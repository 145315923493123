export const SERVICES = [
  {
    title: 'Hair',
    sections: [
      {
        title: 'Cutting',
        services: [
          {
            name: 'Dry Cut',
            price: 15,
          },
          {
            name: 'Cut & Blow Dry',
            price: 20,
          },
          {
            name: 'Restyle',
            price: 27,
          },
          {
            name: "Child's Cut",
            price: 7,
          },
        ],
      },
      {
        title: 'Styling',
        services: [
          {
            name: 'Blow Dry (Short Hair)',
            price: 12,
          },
          {
            name: 'Blow Dry (Long Hair)',
            price: 20,
          },
          {
            name: 'Perm',
            price: 35,
            from: true,
          },
          {
            name: 'Occasion Hair',
            price: 35,
            from: true,
          },
        ],
      },
      {
        title: 'Colouring',
        services: [
          {
            name: 'Full Head Colour',
            price: 50,
            from: true,
          },
          {
            name: 'Root Retouch',
            price: 44,
            from: true,
          },
          {
            name: 'T-Section (Parting)',
            price: 40,
            from: true,
          },
          {
            name: 'Half Head Highlights',
            price: 50,
            from: true,
          },
          {
            name: 'Full Head Highlights',
            price: 55,
            from: true,
          },
          {
            name: 'Balayage',
            price: 65,
            from: true,
          },
          {
            name: 'Colour & Foils',
            price: 65,
            from: true,
          },
          {
            name: 'Olaplex Addition',
            price: 10,
          },
        ],
      },
    ],
  },
  {
    title: 'Beauty',
    sections: [
      {
        title: 'Eyebrow Treatments',
        services: [
          {
            name: 'Brow Wax',
            price: 7,
          },
          {
            name: 'Brow Tint',
            price: 7,
          },
          {
            name: 'Brow Wax & Tint',
            price: 12,
          },
          {
            name: 'Lash Tint',
            price: 7,
          },
        ],
      },
      {
        title: 'Nail Treatments',
        services: [
          {
            name: 'Gel Nails',
            price: 17,
          },
          {
            name: 'Gel Toes',
            price: 17,
          },
          {
            name: 'Luxury Pedicure',
            price: 25,
          },
          {
            name: 'Luxury Manicure',
            price: 27,
          },
        ],
      },
    ],
  },
];
