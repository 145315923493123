import * as React from 'react';
import Layout from '../components/layout';
import Body from '../components/body';
import Seo from '../components/seo';
import { SERVICES } from '../constants/services';
import PageTitle from '../components/page-title';
import Paragraphs from '../components/paragraphs';
import Container from '../components/container';

const ServicesPage = ({ transitionStatus, entry, exit }) => (
  <Layout transitionStatus={transitionStatus}>
    <Seo
      title='Services and Pricing'
      description='Price list for Halo Hair Derbyshire - Mobile Hairdressing'
    />
    <div className='my-20'>
      <Container transitionStatus={transitionStatus} entry={entry} exit={exit}>
        <Body>
          <PageTitle title='Services & Pricing' />
          <Paragraphs
            paragraphs={[
              'Enjoy a hair or beauty service in the comfort of your own home, no hassle, no travelling, at a time that suits you.',
            ]}
          />
          <div className='grid grid-cols-1 md:grid-cols-2 gap-20 my-16'>
            {SERVICES.map((service) => (
              <div key={service.title}>
                <div className='text-xl md:text-base font-extrabold'>
                  {service.title}
                </div>
                {service.sections.map((section) => (
                  <div key={section.title}>
                    <div className='my-6 align-left'>{section.title}</div>
                    {section.services.map((service) => (
                      <div
                        key={service.name}
                        className='flex justify-between p-1 hover:bg-white hover:bg-opacity-50 transition duration-500 ease-in-out'
                      >
                        <div>{service.name}</div>
                        <div>
                          {service.from && (
                            <span className='text-xs'>from</span>
                          )}{' '}
                          £{service.price}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <p className='text-sm flex flex-col space-y-2'>
            <b className='underline'>Please note</b>
            <span>
              If you need to cancel or change the appointment date please try to
              contact me 24 hours before your appointment.
            </span>
            <span>Toners are an additional £10</span>
            <span>
              The use of additional tubes of colour will be charged at £10 per
              tube
            </span>
            <span>Minimum call out fee of £12</span>
          </p>
        </Body>
      </Container>
    </div>
  </Layout>
);

export default ServicesPage;
